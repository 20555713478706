<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ schedulerTitle }}</h1>
      <div class="btns-container">
        <VasionButton
        id="save-button"
        name="save-button"
        classProp="primary"
        :isDisabled="!isDirty"
        @vasionButtonClicked="save()"
      >
        Save
      </VasionButton>

      <VasionButton
        id="cancel-button"
        class="last-btn"
        name="cancel-button"
        classProp="secondary"
        @vasionButtonClicked="cancel()"
      >
        Cancel
      </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div id="doc-template-details">
        <AutomationEngineSharedHeader
          ref="header"
          :nameLabel="'DOCUMENT TEMPLATE NAME'"
          @permissions="showMainSection = false"
          @general-automation="showMainSection = true"
          @dirty="isDirty = true"
        />

        <div v-if="showMainSection" id="doc-template-main" class="collapsable-section">
          <h2 class="subheader">
            Build Document Template
          </h2>
          <div class="row">
            <VasionDropList
              v-slot="slotItem"
              v-model="processingType"
              class="row-field"
              :dataArray="processingTypeList"
              width="396"
              title="PROCESSING TYPE"
              placeholder="Select Processing Type..."
              type="plain-list"
              displayName="name"
              valueName="value"
              :required="true"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div class="row">
            <VasionInput
              id="folder-name"
              v-model="folderName"
              class="row-field browse-input"
              title="FOLDER TO PROCESS"
              inputType="top-white"
              name="folder-name"
              :required="true"
              :width="'306'"
            />
            <VasionButton
              id="browse"
              class="browse-button"
              :classProp="'primary'"
              @vasionButtonClicked="toggleBrowseFolderDialog('folder')"
            >
              Browse
            </VasionButton>
          </div>
          <div class="row">
            <VasionDropList
              v-slot="slotItem"
              v-model="documentTemplate"
              class="row-field"
              :dataArray="documentTemplateList"
              width="396"
              title="DOCUMENT TEMPLATE"
              placeholder="Select Document Template..."
              type="plain-list"
              valueName="documentTemplateID"
              displayName="name"
              :required="true"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div class="row">
            <VasionDropList
              v-slot="slotItem"
              v-model="mergeOption"
              class="row-field"
              :dataArray="mergeOptionList"
              width="396"
              title="MERGE OPTION"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div class="row" v-show="mergeOption.value == 0">
            <label class="vasion-input-label-top">PROCESSING OPTION *</label>
            <div class="radio-buttons">
              <md-radio v-model="afterProcessing" value="delete" @change="isDirty = true">
                Delete File After Processing
              </md-radio>
              <md-radio v-model="afterProcessing" value="move" @change="isDirty = true">
                Move File After Processing
              </md-radio>
            </div>
          </div>
          <div class="row" v-show="mergeOption.value == 0 && afterProcessing==='move'">
            <VasionInput
              id="success-folder-name"
              v-model="successFolderName"
              class="row-field browse-input"
              title="SUCCESS FOLDER"
              inputType="top-white"
              name="success-folder-name"
              :required="true"
              :width="'306'"
            />
            <VasionButton
              id="browse"
              class="browse-button"
              :classProp="'primary'"
              @vasionButtonClicked="toggleBrowseFolderDialog('success')"
            >
              Browse
            </VasionButton>
            <VasionInput
              id="failed-folder-name"
              v-model="failedFolderName"
              class="row-field browse-input"
              title="FAILED FOLDER"
              inputType="top-white"
              name="failed-folder-name"
              :required="true"
              :width="'306'"
            />
            <VasionButton
              id="browse"
              class="browse-button"
              :classProp="'primary'"
              @vasionButtonClicked="toggleBrowseFolderDialog('failed')"
            >
              Browse
            </VasionButton>
          </div>
        </div>
      </div>
    </div>

    <md-dialog id="browse-folders-dialog" :md-active.sync="showBrowseFoldersDialog" :md-click-outside-to-close="false">
      <div class="folder-dialog-browse">
        <VasionFolders />
      </div>
      <div class="align-right">
        <VasionButton
          id="browse-cancel"
          classProp="secondary"
          @vasionButtonClicked="toggleBrowseFolderDialog()"
        >
          Cancel
        </VasionButton>
        <VasionButton
          id="browse-ok"
          classProp="primary"
          @vasionButtonClicked="folderDialogOK()"
        >
          OK
        </VasionButton>
      </div>
    </md-dialog>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarText"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
/* eslint-disable valid-typeof */
/* eslint-disable no-mixed-operators */

import AutomationEngineSharedHeader from '@/views/admin/automationEngine/AutomationEngineSharedHeader.vue';

export default {
  name: 'EditScheduledDocumentTemplate',
  components: {
    AutomationEngineSharedHeader,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      afterProcessing: 'move',
      documentTemplate: 0,
      failedFolderName: '',
      failedFolderId: 0,
      folderId: 0,
      folderName: '',
      isDirty: false,
      mergeOption: {value: 0},
      mergeOptionList: [{name: 'None', value: 0}, {name: 'Insert Before', value: 1}, {name: 'Insert After', value: 2}],
      processingType: {value: 0},
      processingTypeList: [{name: 'Vasion Record', value: 0}],
      schedulerId: 0,
      schedulerTitle: '',
      showBrowseFoldersDialog: false,
      showLeaveDialog: false,
      showMainSection: true,
      showSnackbar: false,
      snackbarImage: false,
      snackbarTitle: '',
      snackbarText: '',
      successFolderName: '',
      successFolderId: 0,
    }
  },
  computed: {
    activeScheduler() { return this.$store.state.automationEngine.activeScheduler },
    documentTemplateList() {
      return this.$store.state.common.documentTemplates
    },
    selectedFolder() { return this.$store.state.common.selectedFolder },
  },
  async created() {
    await this.$store.dispatch('automationEngine/clearActiveScheduler')
    await this.$store.dispatch('common/getDocumentTemplates')

    if (this.$route.params.schedulerId && parseInt(this.$route.params.schedulerId, 10) > 0) {
      await this.$store.dispatch('automationEngine/getDocumentTemplateScheduler', parseInt(this.$route.params.schedulerId, 10))
      if (this.activeScheduler) {
        this.setSchedulerValues()
      }
    }
    this.setHeaderDetails(this.schedulerId, this.activeScheduler?.runDetails)

    this.schedulerTitle = this.schedulerId && this.schedulerId > 0 && this.activeScheduler.runDetails ? this.activeScheduler.runDetails.name : 'Untitled Document Template'
  },
  methods: {
    cancel() { this.$router.push({ name: 'ScheduledDocumentTemplate' }) },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    folderDialogOK() {
      if (this.selectedFolder
        && this.selectedFolder.isSelected === true && this.fileBrowseField !== '') {
        // Set folder info here, including ID and name
        if (this.fileBrowseField === 'folder') {
          this.isDirty = true
          this.folderId = this.selectedFolder.item.FolderId
          this.folderName = this.selectedFolder.item.Name
        } else if (this.fileBrowseField === 'success') {
          this.isDirty = true
          this.successFolderId = this.selectedFolder.item.FolderId
          this.successFolderName = this.selectedFolder.item.Name
        } else {
          this.isDirty = true
          this.failedFolderId = this.selectedFolder.item.FolderId
          this.failedFolderName = this.selectedFolder.item.Name
        }
      } else {
        this.folderId = null
        this.folderName = ''
      }

      this.toggleBrowseFolderDialog()
    },
    hideSnackbar() { this.showSnackbar = false },
    async save() {
      const { header } = this.$refs
      if (await this.validate(header)) {
        const payload = {
          runDetails: await header.getValues(),
          ProcessingType: this.processingType.value,
          FolderToProcessName: this.folderName,
          FolderToProcessID: this.folderId,
          DocumentTemplateID: this.documentTemplate.documentTemplateID,
          DeleteWhenComplete: this.mergeOption.value == 0 ? this.afterProcessing === 'delete': true,
          MoveSuccessFolderPath: this.mergeOption.value == 0 && this.afterProcessing == 'move' ? this.successFolderName : null,
          MoveSuccessFolderID: this.mergeOption.value == 0 && this.afterProcessing == 'move' ? this.successFolderId : null,
          MoveFailureFolderPath: this.mergeOption.value == 0 && this.afterProcessing == 'move' ? this.failedFolderName : null,
          MoveFailureFolderID: this.mergeOption.value == 0 && this.afterProcessing == 'move' ? this.failedFolderId : null,
          MergeOption: this.mergeOption.value,
        }
        const returnValue = await this.$store.dispatch('automationEngine/saveDocumentTemplateScheduler', payload)
        if (!returnValue || !returnValue.data || returnValue.data.Value) {
          let errorText = returnValue?.data?.Value
          if (!errorText) {
            errorText = "Unknown error occurred saving template.  Check the server logs."
          }
          this.snackbarTitle = 'ERROR'
          this.snackbarText = errorText
          this.showSnackbar = true
          this.snackbarImage = false
          console.warn(errorText)
        } else {
          this.isDirty = false
          this.snackbarTitle = 'SUCCESS'
          this.snackbarText = 'Document Template saved successfully'
          this.showSnackbar = true
          this.snackbarImage = true
          
          setTimeout(() => {
            this.$router.push({ name: 'ScheduledDocumentTemplate' })
          }, 1500)
        }
      }
    },
    setHeaderDetails(id, details) {
      const { header } = this.$refs
      if (id > 0) {
        header.setValues(details)
      } else {
        header.setValues()
      }
    },
    setSchedulerValues() {
      this.schedulerId = this.activeScheduler.runDetails.schedulerID
      this.processingType.value = this.activeScheduler.ProcessingType
      this.folderName = this.activeScheduler.FolderToProcessName
      this.folderId = this.activeScheduler.FolderToProcessID
      this.activeScheduler.DeleteWhenComplete ? this.afterProcessing = 'delete' : this.afterProcessing = 'move'
      this.successFolderId = this.activeScheduler.MoveSuccessFolderID
      this.successFolderName = this.activeScheduler.MoveSuccessFolderPath
      this.failedFolderId = this.activeScheduler.MoveFailureFolderID
      this.failedFolderName = this.activeScheduler.MoveFailureFolderPath
      this.mergeOption = this.mergeOptionList.find((mergeOption) => {
        return mergeOption.value === this.activeScheduler.MergeOption
      })
      if (this.activeScheduler.DocumentTemplateID) {
        this.documentTemplate = this.documentTemplateList.find((documentTemplate) => {
          return documentTemplate.documentTemplateID === this.activeScheduler.DocumentTemplateID
        })
      }
    },
    toggleBrowseFolderDialog(field) {
      this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog
      this.fileBrowseField = ''
      if (this.showBrowseFoldersDialog && field !== '') {
        this.fileBrowseField = field
      }
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    async validate(header) {
      let error = false
      let errorText = ''

      await header.validate()
      if (header.errors !== '') {
        error = true
        errorText = `${errorText} ${header.errors}\n`
      }
      // Any other validation for this component goes here ...
      if (this.processingType.value !== 0) {
        error = true
        errorText = `${errorText} - Please select a Processing Type\n`
      }
      if (!this.folderName) {
          error = true
          errorText = `${errorText} - Please select a Folder to Process\n`
      }
      if (!this.documentTemplate.documentTemplateID) {
          error = true
          errorText = `${errorText} - Please select a Document Template\n`
      }
      
      if (!this.mergeOption) {
        error = true
        errorText = `${errorText} - Please select a Merge Option\n`
      } else if (this.mergeOption.value == 0) {
        if (!this.afterProcessing) {
          error = true
          errorText = `${errorText} - Please select a Processing Option\n`
        } else if (this.afterProcessing == 'move') {
          if (!this.successFolderName) {
            error = true
            errorText = `${errorText} - Please select a Success Folder\n`
          }
          if (!this.failedFolderName) {
            error = true
            errorText = `${errorText} - Please select a Failed Folder\n`
          }
        }
      }
      if (error) {
        this.snackbarTitle = 'ERROR'
        this.snackbarText = errorText
        this.showSnackbar = true
        this.snackbarImage = false
        console.warn(errorText)
        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss">
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  #doc-template-details {
    width: 100%;
    .row {
      width: 100%;
      margin: 13px 0;
      display: flex;

      .section-title {
        @include SECTION-HEADER;
        color: $grey-400;
        display: block;
        margin: 5px 0;
      }

      .subheader {
        @include SubHeadline;
      }

      .row-field {
        margin: 0 14px 5px 0;

        &.checkbox {
          width: 390px;
        }
      }

      .browse-input {
        margin-right: 5px;
      }

      .vasion-button {
        &.browse-button {
          button {
            margin-top: 22px;
            margin-left: 0;
            margin-right: 13px;
          }
        }
      }
    }

    .collapsable-section {
      float: left;
      margin-top: 20px;
      width: 100%;

      .subheader {
        @include SubHeadline;
        width: 400px;
        display: block;
        float: left;
        margin-top: 5px;
      }

      .subtitle {
        @include Subtitle;
      }
    }
  }

  #browse-folders-dialog {
    .folder-dialog-browse {
      width: 400px;
      height: 300px;
      overflow: auto;
    }

    .align-right {
      text-align: right;
    }
  }

  .radio-buttons {
    margin-top: 8px;
  }
  .md-radio.md-theme-default.md-checked .md-ripple {
    color: $secondary !important;
  }
  .md-radio.md-theme-default.md-checked .md-radio-container {
    border-color: $secondary !important;
  }
  .md-radio.md-theme-default.md-checked .md-radio-container:after {
    background-color: $secondary !important;
  }
</style>
